<template>
  <div class="grid justify-content-center">
    <div class="col-12 md:col-6">
      <div class="card my-4 text-center">
        <Avatar
          class="bg-green-500 text-white"
          icon="pi pi-check-circle"
          size="large"
        />
        <div class="text-xl font-bold text-success mt-3">
          Your password has been changed successfully
        </div>
        <hr />
        <router-link to="/">
          <Button label="Home" class="p-button-outlined" icon="pi pi-home" />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data: function () {
    return {};
  },
  methods: {},
};
</script>
